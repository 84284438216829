import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Styles from './css/iphonecases.module.css'
import { Helmet } from "react-helmet"
import Sidebar from "../components/sidebar/sidebar"

const IPhonecases = () => (
  <Layout>

    <Helmet>

      <title>Colorful iPhone Cases</title>
      <meta name="description" content="Explore a large collection of colorful iPhone Cases with unique and high quality designs." />
      <meta name="keywords" content="iPhone Phone Cases, Colorful iPhone Case, iPhone Case, Colorful iPhone Cases, Bright iPhone Cases, Trippy iPhone Phone Cases, Hippie iPhone Cases, Psychedlic iPhone Cases, vivid iphone cases" />
      <meta property="og:image" content="../images/CustomogThumbnail.png" />
    </Helmet>
    <div className={Styles.breadcrumb}><Link className={Styles.breadcrumbLink} to="/">Home</Link>{' > '} iPhone Cases</div>
    <div className={Styles.mainDiv}>
      <Sidebar />

      <div className={Styles.container}>
        <h1 className={Styles.containerHeader}>Choose Your iPhone Model</h1>
        {/* iPhone 12 */}
        <div className={Styles.catOptionContainer}>
          <div className={Styles.visual}></div>
          <div>
            <Link to="/iphone-12-cases" className={Styles.branddivVariant}>
              <h3 className={Styles.brandtitlevariant}>iPhone 12</h3>
            </Link>
            <Link to="/iphone-12-pro-cases" className={Styles.branddivVariant}>
              <h3 className={Styles.brandtitlevariant}>iPhone 12 Pro</h3>
            </ Link>
            <Link to="/iphone-12-pro-max-cases" className={Styles.branddivVariant}>
              <h3 className={Styles.brandtitlevariant}>iPhone 12 Pro Max</h3>
            </ Link>
            <Link to="/iphone-12-mini-cases" className={Styles.branddivVariant}>
              <h3 className={Styles.brandtitlevariant}>iPhone 12 Pro Mini</h3>
            </Link>
          </div>
        </div>
        
             {/* iPhone 11 */}
        <div className={Styles.catOptionContainer}>
          <div className={Styles.v11}></div>
          <div>
            <Link to="/iphone-11-cases" className={Styles.branddivVariant}>
              <h3 className={Styles.brandtitlevariant}>iPhone 11</h3>
            </Link>
            <Link to="/iphone-11-pro-cases" className={Styles.branddivVariant}>
              <h3 className={Styles.brandtitlevariant}>iPhone 11 Pro</h3>
            </ Link>
            <Link to="/iphone-11-pro-max-cases" className={Styles.branddivVariant}>
              <h3 className={Styles.brandtitlevariant}>iPhone 11 Pro Max</h3>
            </ Link>
          </div>
        </div>

            {/* iPhone X */}
        <div className={Styles.catOptionContainer}>
        <div className={Styles.vx}></div>
          <div>
            <Link to="/iphone-x-cases" className={Styles.branddivVariant}>
              <h3 className={Styles.brandtitlevariant}>iPhone X</h3>
            </Link>
            <Link to="/iphone-xr-cases" className={Styles.branddivVariant}>
            <h3 className={Styles.brandtitlevariant}>iPhone XR</h3>
            </ Link>
            <Link to="/iphone-xs-cases" className={Styles.branddivVariant}>
            <h3 className={Styles.brandtitlevariant}>iPhone XS</h3>
            </ Link>
            <Link to="/iphone-xs-max-cases" className={Styles.branddivVariant}>
          <h3 className={Styles.brandtitlevariant}>iPhone XS Max</h3>
        </Link>
          </div>
        </div>


                         {/* iPhone 8 */}
      <div className={Styles.catOptionContainer}>
      <div className={Styles.v8}></div>
        <div>
          <Link to="/iphone-8-cases" className={Styles.branddivVariant}>
            <h3 className={Styles.brandtitlevariant}>iPhone 8</h3>
          </Link>
          <Link to="/iphone-8-plus-cases" className={Styles.branddivVariant}>
            <h3 className={Styles.brandtitlevariant}>iPhone 8 Plus</h3>
          </Link>
        </div>
      </div>


                      {/* iPhone 7 */}
      <div className={Styles.catOptionContainer}>
          <div className={Styles.v7}></div>
            <div>
              <Link to="/iphone-7-cases" className={Styles.branddivVariant}>
                <h3 className={Styles.brandtitlevariant}>iPhone 7</h3>
              </Link>
              <Link to="/iphone-7-plus-cases" className={Styles.branddivVariant}>
                <h3 className={Styles.brandtitlevariant}>iPhone 7 Plus</h3>
              </Link>
          </div>
      </div>

       
      </div>


    </div>
  </Layout>
)

export default IPhonecases
