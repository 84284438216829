import React from "react"
import Styles from "./sidebar.module.css"
import { Link } from "gatsby"
const Sidebar = () => {
    return (
        <nav className={Styles.mainDiv}>
            <h2><Link to="/iphonecases" className={Styles.link}>iPhone Cases</Link></h2>
            <ul className={Styles.list}>
                <li><Link to="/iphone-12-cases" className={Styles.link}>iPhone 12</Link></li>
                <li><Link to="/iphone-12-pro-cases" className={Styles.link}>iPhone 12 Pro</Link></li>
                <li><Link to="/iphone-12-pro-max-cases" className={Styles.link}>iPhone 12 Pro Max</Link></li>
                <li><Link to="/iphone-12-mini-cases" className={Styles.link}>iPhone 12 Mini</Link></li>
                <li><Link to="/iphone-11-cases" className={Styles.link}>iPhone 11</Link></li>
                <li><Link to="/iphone-11-pro-cases" className={Styles.link}>iPhone 11 Pro</Link></li>
                <li><Link to="/iphone-11-pro-max-cases" className={Styles.link}>iPhone 11 Pro Max</Link></li>
                <li><Link to="/iphone-x-cases" className={Styles.link}>iPhone X</Link></li>
                <li><Link to="/iphone-xr-cases" className={Styles.link}>iPhone XR</Link></li>
                <li><Link to="/iphone-xs-cases" className={Styles.link}>iPhone XS</Link></li>
                <li><Link to="/iphone-xs-max-cases" className={Styles.link}>iPhone XS Max</Link></li>
                <li><Link to="/iphone-8-cases" className={Styles.link}>iPhone 8</Link></li>
                <li><Link to="/iphone-8-plus-cases" className={Styles.link}>iPhone 8 Plus</Link></li>
                <li><Link to="/iphone-7-cases" className={Styles.link}>iPhone 7</Link></li>
                <li><Link to="/iphone-7-plus-cases" className={Styles.link}>iPhone 7 Plus</Link></li>
            </ul>
            <h2><Link to="/samsungcases" className={Styles.link}>Samsung Cases</Link></h2>
            <ul className={Styles.list}>
                <li><Link to="/samsung-galaxy-s21-cases" className={Styles.link}>Samsung Galaxy S21</Link></li>
                <li><Link to="/samsung-galaxy-s21-ultra-cases" className={Styles.link}>Samsung Galaxy S21 Ultra</Link></li>
                <li><Link to="/samsung-galaxy-s21-plus-cases" className={Styles.link}>Samsung Galaxy S21 Plus</Link></li>
                <li><Link to="/samsung-galaxy-s20-cases" className={Styles.link}>Samsung Galaxy S20</Link></li>
                <li><Link to="/samsung-galaxy-s20-ultra-cases" className={Styles.link}>Samsung Galaxy S20 Ultra</Link></li>
                <li><Link to="/samsung-galaxy-s20-plus-case" className={Styles.link}>Samsung Galaxy S20 Plus</Link></li>
                <li><Link to="/samsung-galaxy-s10-cases" className={Styles.link}>Samsung S10</Link></li>
                <li><Link to="/samsung-galaxy-s10-plus-cases" className={Styles.link}>Samsung Galaxy S10 Plus</Link></li>
                <li><Link to="/samsung-galaxy-s10e-cases" className={Styles.link}>Samsung Galaxy S10E </Link></li>
               
            </ul>
        </nav>
    )
}

export default Sidebar